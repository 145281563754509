<template>
  <div class="begin mt-20 lg:mt-0 mb-10" v-if="transactionDetails">
    <div class="">
      <div class="flex justify-between items-center mb-4 cursor-pointer">
        <div class="flex items-center" @click="$emit('oga', false)">
          <div class="mr-4">
            <img
              src="../../../assets/icons/arrow-left.svg"
              alt="logo"
              original
            />
          </div>
          <div class="font-bold text-2xl">Retour</div>
        </div>
      </div>
      <confirmation
        :activation="RejectBill"
        v-if="RejectBill"
        description="Vous êtes sur le point de rejeter la facture du prestataire, souhaitez-vous continuer ?"
        background="#EC0001"
        :boutonType="boutontype"
        bouton-label="Confimer et rejeter"
        boutonWidth="60px"
        @oga="backConfirm3"
        @fermer="fermer"
        @continuer="continuer"
      />
      <confirmation
        :activation="approuveBill"
        v-if="approuveBill"
        description="Vous êtes sur le point d’approuver une nouvelle facture, souhaitez-vous continuer ?"
        background="#2C5AA0"
        :boutonType="boutontype"
        bouton-label="Confimer et enregistrer"
        boutonWidth="60px"
        @oga="backConfirm"
      />
      <confirmation-payment
        :activation="paymentapprouved"
        v-if="paymentapprouved"
        description="Facture approuvée, passer l’enregistrement du paiement?"
        background="#2C5AA0"
        :boutonType="boutontype"
        bouton-label="Passer au paiement"
        boutonWidth="60px"
        @oga="backConfirm2"
        @fermer="fermer"
        @continuer="continuer"
      />
    </div>

    <new-details
      :driver-data="oneTransactionDetails"
      @validatedTransaction="$emit('validatedTransaction')"
    />
  </div>
</template>

<script>
import arrowLeft from "@/assets/icons/arrow-left.svg";
import profile1 from "@/assets/icons/ic_profile1.svg";
import powerpoint from "@/assets/icons/ic_powerpoint.svg";
import Confirmation from "@/components/popups/confirmation.vue";
import ConfirmationPayment from "@/components/popups/confirmation-payment.vue";
import newDetails from "./new-details.vue";

export default {
  name: "index-postes",
  components: {
    newDetails,
    Confirmation,
    ConfirmationPayment,
  },
  props: {
    oneTransactionDetails: Object,
  },
  data() {
    return {
      icons: { profile1, powerpoint, arrowLeft },
      isApprouved: false,
      ConfirmReject: false,
      RejectBill: false,
      transactionDetails: true,
      approuveBill: false,
      paymentapprouved: false,
      message:
        "Vous êtes sur le point de rejeter la facture du prestataire, souhaitez-vous continuer ?",
      boutonLabel: "Confimer et rejeter",
      boutonType: null,
      btnBack: "",
    };
  },
  created() {},

  methods: {
    closeConfirmReject() {
      this.$emit("closeConfirmReject", false);
      this.ConfirmReject = false;
    },
    backtransactionDetails() {
      this.$emit("backtransactionDetails", false);
      this.billpayment = false;
      this.transactionDetails = true;
    },
    backConfirm(a) {
      this.approuveBill = a;
      this.approuveBill = false;
      this.paymentapprouved = true;
    },
    backConfirm2(a) {
      this.approuveBill = a;
      this.approuveBill = false;
      this.paymentapprouved = false;
      this.transactionDetails = false;
    },
    backConfirm3(a) {
      this.RejectBill = a;
      this.RejectBill = false;
      this.ConfirmReject = true;
    },
    fermer() {
      this.$emit("oga", false);
    },
    continuer() {
      this.$emit("oga", true);
      this.paymentapprouved = false;
      this.transactionDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  background-color: #e7f3ee;
  color: #0f784d;
}
.ip {
  color: #7f8896;
}
.begin {
  // min-height: 100vh;
  // max-width: 100;
}
</style>
