<template>
  <div class="flex flex-wrap">
    <div class="w-1/2" v-for="(item, i) in donnes" :key="i">
      <div class="bg-white shadow-lg rounded-lg py-1 px-5 mr-2 lg:mr-4 mb-2">
        <div class="flex justify-between items-center">
          <div>
            <div class="font-bold text-gray-700 text-sm mb-0">
              <div v-if="i === 'ordersList'">Transactions</div>
              <div v-if="i === 'waitingList'">En attentes</div>
              <div v-if="i === 'validatedList'">Validées</div>
              <div v-if="i === 'failedList'">Echouées</div>
            </div>
            <div class="font-extrabold text-xl">
              {{ item.length }}
            </div>
          </div>
          <div>
            <div v-if="i === 'ordersList'">
              <img
                :src="icons.ic_allTransations"
                height="35"
                width="35"
                original
              />
            </div>
            <div v-if="i === 'waitingList'">
              <img :src="icons.ic_waiting" height="35" width="35" original />
            </div>
            <div v-if="i === 'validatedList'">
              <img :src="icons.ic_validated" height="35" width="35" original />
            </div>
            <div v-if="i === 'failedList'">
              <img :src="icons.ic_failed" height="35" width="35" original />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ic_allTransations from "@/assets/icons/ic_allTransactions.svg";
import ic_waiting from "@/assets/icons/ic_waiting.svg";
import ic_validated from "@/assets/icons/ic_validated.svg";
import ic_failed from "@/assets/icons/ic_failed.svg";

export default {
  name: "type-stat",
  props: {
    donnes: {
      type: Object,
      default: null,
    },
  },
  components: {},

  data() {
    return {
      icons: {
        ic_allTransations,
        ic_waiting,
        ic_validated,
        ic_failed,
      },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.ip {
  color: #606060;
}
</style>
