<template>
  <div class="w-full bg-white py-5 rounded-lg border border-gray-100">
    <div class="flex px-5 items-center mb-1">
      <div>
        <img
          :src="icons.company"
          width="32"
          height="32"
          class="mr-2"
          original
        />
      </div>
      <div class="font-montserrat font-bold text-base">
        {{ driverData.firstName + " " + driverData.lastName }}
      </div>
    </div>
    <div class="ml-12 pl-5 mb-0">
      <div class="font-montserrat font-normal text-sm text-gray-600 mb-2">
        {{ driverData.personnalEmail }} |
        <span
          class="text-base text-identityColor"
          v-if="driverData.type === 'BUY'"
          >{{ driverData.flashReceptNumber }}
          <span class="text-green-500 ml-4">Flash</span></span
        >
        <span class="text-base text-identityColor" v-else>{{
          driverData.phoneNumber
        }}</span>
      </div>
      <div class="flex mb-1">
        <div class="font-montserrat font-normal text-sm text-gray-500 mr-2">
          Reseau :
        </div>
        <div class="font-montserrat font-medium text-sm text-blue-800">
          {{ driverData.gsmNetwork }}
        </div>
      </div>
      <div class="flex mb-1">
        <div class="font-montserrat font-normal text-sm text-gray-500 mr-2">
          Sats :
        </div>
        <div class="font-montserrat font-medium text-sm text-blue-800">
          {{ driverData.amountSats }}
        </div>
      </div>
      <div class="flex mb-1">
        <div class="font-montserrat font-normal text-sm text-gray-500 mr-2">
          Fiat :
        </div>
        <div class="font-montserrat font-medium text-sm text-blue-800">
          {{ driverData.amountXof }}
        </div>
      </div>
      <div class="flex mb-1">
        <div class="font-montserrat font-normal text-sm text-gray-600 mr-2">
          Service :
        </div>
        <div class="font-montserrat font-medium text-sm text-identityColor">
          {{ driverData.type }}
        </div>
      </div>
      <!-- <div class="flex items-center">
        <div class="font-montserrat font-normal text-sm text-gray-500 mr-2">
          Address :
        </div>
        <div class="font-montserrat font-medium text-sm text-blue-800">
          <span class="text-base" v-if="driverData.type === 'SELL'"
            >{{ driverData.flashReceptAdress.slice(0, 30) }}
            <span class="font-normal text-base text-green-500 ml-4"
              >Flash</span
            ></span
          >
          <span class="text-base" v-else>{{
            driverData.receptionAddress
          }}</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import company from "@/assets/icons/ic_company.svg";

export default {
  name: "index-intitule",
  components: {},
  props: {
    driverData: Object,
  },
  data() {
    return {
      icons: { company },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  color: #2c5aa0;
}
</style>
