<template>
  <div>
    <div
      class="flex justify-center items-center cursor-pointer bout"
      :style="{
        height: height,
        width: width,
        'border-radius': radius,
        border: border,
        background: background,
        color: color,
        'font-weight': weight,
        'font-size': size,
      }"
      @click="continuer"
    >
      <div v-if="!special">
        {{ first.substr(0, 1).toUpperCase() }}
        {{ second.substr(0, 1).toUpperCase() }}
      </div>

      <div v-if="special">
        {{ special }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CedricDivider",
  props: {
    height: {
      type: String,
      default: "50px",
    },
    width: {
      type: String,
      default: "50px",
    },
    weight: {
      type: String,
      default: "400",
    },
    icon: {
      type: Object,
      default: null,
    },
    radius: {
      type: String,
      default: "100px",
    },
    border: {
      type: String,
      default: "none",
    },
    color: {
      type: String,
      default: "#fff",
    },
    first: {
      type: String,
      default: "",
    },
    second: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "18px",
    },
    special: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      background: "green",
      allColor: [
        "#2B9E7C",
        "#5138EE",
        "#FA4A4D",
        "#FFB300",
        "#00C24E",
        "#32324D",
        "#e9b909",
        "#FB8282",
        "#136AAB",
        "rgba(35, 0, 255, 0.39)",
      ],
    };
  },

  created() {
    const id = Math.floor(Math.random() * 4);
    this.background = this.allColor[id];
  },

  methods: {
    continuer() {
      this.$emit("info", this.label);
      this.$emit("oga", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.bout:hover {
  border: 1px solid gray !important;
}
</style>
