<template>
  <popup-base>
    <div
      class="begin bg-white m-auto rounded-lg md:w-3/5 sm:w-10/12 w-11/12"
      :style="{ minHeight: '350px' }"
    >
      <div class="content">
        <div class="header flex items-center justify-between border-b-2 p-8">
          <div class="text-2xl font-bold font-serif">
            Valider une transaction
          </div>
          <div class="icon w-9 h-9 cursor-pointer" @click="$emit('close')">
            <img :src="images.close" alt="close" original />
          </div>
        </div>
        <br />

        <div class="overflow-y-scroll px-8">
          <div class="form">
            <div class="form-content" v-if="isGood">
              <div v-if="transactionSelect.type === 'BUY'">
                <div class="label text-gray-700 font-normal text-base mb-2">
                  Hash de la transaction *
                </div>
                <div class="input-bloc">
                  <inputo
                    :placeholder="this.transactionSelect.transactionHash"
                    height="50px"
                    border="1px solid #CDD1D6"
                    radius="4px"
                    @info="(val) => (hash = val)"
                  ></inputo>
                </div>
              </div>
              <div v-else>
                <div class="label text-gray-700 font-normal text-base mb-2">
                  Id momo de la transaction *
                </div>
                <div class="input-bloc">
                  <inputo
                    :placeholder="this.transactionSelect.momoId"
                    height="50px"
                    border="1px solid #CDD1D6"
                    radius="4px"
                    @info="(val) => (idMobiMoney = val)"
                  ></inputo>
                </div>
              </div>
            </div>
            <div class="form-content" v-else>
              <div class="label text-gray-700 font-normal text-base mb-2">
                Raison de rejet *
              </div>
              <div class="input-bloc">
                <inputo
                  placeholder="note de rejet"
                  height="50px"
                  border="1px solid #CDD1D6"
                  radius="4px"
                  @info="(val) => (note = val)"
                ></inputo>
              </div>
            </div>
            <br />
            <!-- <div class="form-content">
              <div class="label text-gray-700 font-normal text-base mb-2">
                Montant de la transaction
              </div>
              <div class="input-bloc">
                <inputo
                  type="number"
                  :value-T="this.transactionSelect.amount"
                  height="50px"
                  border="1px solid #CDD1D6"
                  radius="4px"
                  @info="(val) => (amount = val)"
                ></inputo>
              </div>
            </div> -->
            <br />
            <br />
          </div>
        </div>
        <div class="buttons flex items-center justify-end border-t-2 p-8">
          <div class="cancel">
            <boutton
              background="#EFF0F2"
              color="#949BA7"
              label="Annuler"
              height="40px"
              size="14px"
              padding="14px 24px"
              weight="600"
              radius="4px"
              @oga="$emit('close')"
            />
          </div>
          <div class="Rejected mx-3">
            <boutton
              size="14px"
              background="#ed3f3f"
              height="40px"
              padding="14px 24px"
              label="Rejeter"
              weight="600"
              radius="4px"
              :charge="charge"
              @oga="continuer()"
            />
          </div>
          <div class="validated" v-if="isGood">
            <boutton
              size="14px"
              background="#FF914D"
              height="40px"
              padding="14px 24px"
              label="Enregistrer"
              weight="600"
              radius="4px"
              :charge="charge"
              @oga="validateTransaction()"
            />
          </div>
        </div>
      </div>
    </div>
    <confirmation-reject
      :activation="approuvedReject"
      v-if="approuvedReject"
      description="Transaction inappropriée, rejeter ?"
      background="#2C5AA0"
      :boutonType="boutontype"
      hOne="Rejet"
      boutonLabell="Retour"
      bouton-label="Rejeter"
      boutonWidth="60px"
      @fermer="approuvedReject = false"
      @continuer="rejectTransaction()"
    />
  </popup-base>
</template>

<script>
import boutton from "@/components/helper/add/button";
import popupBase from "@/components/helper/add/popup-base.vue";
import inputo from "@/components/helper/form/input";
// import close from "@/assets/icons/fermer.svg";
import ConfirmationReject from "@/components/popups/confirmation-payment.vue";
import { getFirestore, doc, updateDoc } from "@firebase/firestore";
import close from "@/assets/images/img_close.webp";
//import { useStore } from "vuex";
import http from "@/plugins/https";

export default {
  name: "index-validated",
  components: { boutton, popupBase, inputo, ConfirmationReject },
  props: {
    transactionSelect: {
      type: Object,
      default: function () {
        return {};
      },
    },
    adminConnected: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      icons: { close },
      images: {
        close,
      },
      hash: "",
      note: "",
      idMobiMoney: null,
      litghningAdress: "",
      amount: "",
      charge: false,
      approuvedReject: false,
      isGood: true,
    };
  },
  created() {
    // console.log("con", this.adminConnected);
  },
  methods: {
    async validateTransaction() {
      this.charge = true;
      const db = getFirestore();
      if (this.transactionSelect.type === "BUY") {
        if (this.hash !== "") {
          const id = this.transactionSelect.id;
          const data = {
            transactionHash: this.hash,
          };
          const result = (id, data);
          if (result) {
            //console.log("result", result);
            updateDoc(doc(db, "orders", this.transactionSelect.id), {
              transactionHash: this.hash,
              state: "PAID",
              updatedBy: this.adminConnected,
              updatedAt: new Date().getTime(),
            });
            this.checkDataToSend(this.transactionSelect);
            this.$toast.success("Commande validée");
            this.charge = false;
            this.$emit("close", true);
          } else {
            this.$toast.error("Une erreur s'est produite");
            this.charge = false;
            this.$emit("close", true);
          }
        } else {
          this.charge = false;
          this.$toast.error("Veillez remplir le hash de la transaction");
        }
      } else {
        if (this.idMobiMoney !== null) {
          const id = this.transactionSelect.id;
          const data = {
            momoId: this.idMobiMoney,
          };
          const result = (id, data);
          if (result) {
            //console.log("result", result);
            updateDoc(doc(db, "orders", this.transactionSelect.id), {
              momoId: this.idMobiMoney,
              state: "PAID",
              updatedBy: this.adminConnected,
              updatedAt: new Date().getTime(),
            });
            this.checkDataToSend(this.transactionSelect);
            this.$toast.success("Commande validée");
            this.charge = false;
            this.$emit("close", true);
          } else {
            this.$toast.error("Une erreur s'est produite");
            this.charge = false;
            this.$emit("close", true);
          }
        } else {
          this.charge = false;
          this.$toast.error("Veillez remplir le id momo de la transaction");
        }
      }
    },

    continuer() {
      this.$toast.info(
        "Attention, vous êtes sur le point de rejeter une commande."
      );
      this.approuvedReject = true;
      this.isGood = false;
    },

    async rejectTransaction() {
      this.charge = true;
      const db = getFirestore();
      if (this.note !== "") {
        const id = this.transactionSelect.id;
        const data = {
          rejectedNote: this.note,
        };
        const result = (id, data);
        if (result) {
          updateDoc(doc(db, "orders", this.transactionSelect.id), {
            rejectedNote: this.note,
            state: "FAILED",
            updatedBy: this.adminConnected,
            updatedAt: new Date().getTime(),
          });
          this.$toast.success("Commande rejetée");
          this.charge = false;
          this.$emit("close", true);
        } else {
          this.$toast.error("Une erreur s'est produite");
          this.charge = false;
          this.$emit("close", true);
        }
      } else {
        this.charge = false;
        this.$toast.error("Veillez retourner et remplir la raison");
      }
    },
    checkDataToSend(result) {
      let data = null;
      if (result.type === "BUY") {
        data = `<div style="background-color: #f0f0f0; padding: 20px;">
          <p style="font-size: 18px; font-weight: bold;">Transaction Réussie !</p>
          <p style="font-size: 16px;">Bonjour ${result.firstName}</p>
          <p>Votre transaction a été validée.</p>
          <p style="font-size: 16px;">${result.amountSats} SAT ont été envoyés sur l'adresse: ${result.receptionAddress} avec succès.</p>
          <p>Merci de nous choisir et à très vite.</p>
          <p style="font-weight: bold; text-align: center;">Flash</p>
          <p style="text-align: center;">Email: contact@bitcoinflash.xyz</p>
          <p style="text-align: center;">Téléphone: 00229 61757483</p>
        </div>`;
      } else {
        data = `<div style="background-color: #f0f0f0; padding: 20px;">
          <p style="font-size: 18px; font-weight: bold;">Transaction Réussie !</p>
          <p style="font-size: 16px;">Bonjour ${result.firstName}</p>
          <p>Votre transaction a été validée.</p>
          <p style="font-size: 16px;">${result.amountXof} XOF ont été envoyés sur: ${result.phoneNumber} avec succès.</p>
          <p>Merci de nous choisir et à très vite.</p>
          <p style="font-weight: bold; text-align: center;">Flash</p>
          <p style="text-align: center;">Email: contact@bitcoinflash.xyz</p>
          <p style="text-align: center;">Téléphone: 00229 61757483</p>
        </div>`;
      }

      this.sendMailToUser(data);
    },

    async sendMailToUser(data) {
      this.charge = true;
      await http
        .post("https://pdf.41devs.com/api/v1/kmailler/send", {
          sender: "contact@bitcoinflash.xyz",
          receiver: this.transactionSelect.personnalEmail,
          object: "COMMANDE FLASH VALIDEE",
          content: data,
        })
        .then(() => {
          this.charge = false;
        })
        .catch((error) => {
          this.charge = false;
          console.log(error);
          this.$toast.error("Nous rencontrons des problèmes.");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.description {
  color: #7f8896;
}
.dashboard {
  font-family: $font-family;
  color: #ed3f3f;
}
</style>
