<template>
  <div class="w-full">
    <div
      class="w-full flex web font-serif"
      :style="{
        'font-size': size,
        'font-family': family,
        'font-weight': weight,
        color: color,
      }"
    >
      <div
        v-for="(item, index) in option"
        :key="index"
        class="pl-2 pr-2 cursor-pointer flex items-center nom mr-2"
        :class="{
          item: position === item,
        }"
        @click="selected(item)"
      >
        <div class="p-2 flex items-center">
          {{ item }}
        </div>
      </div>
    </div>

    <div class="mobile pb-6">
      <carousel :items-to-show="3.4" :transition="2000">
        <slide v-for="(item, index) in option" :key="index" class="w-full">
          <div
            class="w-full text-60 cursor-pointer flex items-center justify-center h-10"
            :class="{ item: position === item }"
            @click="selected(item)"
          >
            <div>{{ item }}</div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "steep-select",
  components: {
    Carousel,
    Slide,
  },
  props: {
    option: {
      type: Array,
      default: null,
    },
    value: {
      type: String,
      default: "",
    },
    separator: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: "18px",
    },
    family: {
      type: String,
      default: "sans-serif",
    },
    weight: {
      type: String,
      default: "700",
    },
    color: {
      type: String,
      default: "black",
    },
  },

  data() {
    return {
      position: null,
    };
  },

  created() {
    this.position = this.value;
  },

  methods: {
    selected(item) {
      this.position = item;
      this.$emit("info", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables.scss";

.nom {
  color: black;
}
.item {
  border-bottom: 2.5px solid $principal;
}
.diviser {
  width: 1px;
  background-color: black;
  height: 100%;
}
.mobile {
  display: none;
}

@media screen and (max-width: 769px) and (min-width: 701px) {
  .mobile {
    display: block;
  }
  .web {
    display: none;
  }
  .item {
    border: 2px solid $principal;
  }
}

@media screen and (max-width: 700px) {
  .mobile {
    display: block;
  }
  .web {
    display: none;
  }
  .item {
    border: 2px solid $principal;
  }
}
</style>
