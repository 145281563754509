<template>
  <div class="dashboard">
    <div class="begin">
      <div class="content">
        <div class="w-full border-b">
          <div
            class="flex items-center font-medium text-base py-3 px-2 cursor-pointer"
            @click="$emit('openTransactionDetails')"
          >
            <div class="w-16">
              {{ clef }}
            </div>
            <div class="w-24 uppercase">
              <span v-if="transactionLists.type === 'BUY'">Achat</span>
              <span v-else-if="transactionLists.type === 'SELL'">Vente</span>
              <span v-else>{{ transactionLists.type }}</span>
            </div>
            <div class="w-2/12">
              <div class="flex items-center">
                <div class="zone-reste uppercase mr-2 lg:block hidden">
                  <nameLabell
                    :special="transactionLists.firstName.slice(0, 1)"
                    height="34px"
                    width="34px"
                    size="14px"
                    radius="4px"
                  />
                </div>
                <div>
                  <div class="">
                    {{
                      transactionLists.firstName +
                      " " +
                      transactionLists.lastName
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div class="w-1/12 font-bold text-blue-800">
              {{ transactionLists.amountSats.toLocaleString() }}
            </div>

            <div class="w-1/12 font-bold text-blue-800">
              {{ transactionLists.amountXof.toLocaleString() }}
            </div>

            <div class="w-2/12 flex items-center lg:block hidden">
              <span
                v-if="transactionLists.type === 'BUY'"
                @click.stop="copyMomoId"
              >
                {{ transactionLists.momoId }}</span
              >
              <span v-else @click.stop="copyHash">
                {{
                  transactionLists.transactionHash
                    ? transactionLists.transactionHash.slice(0, 20)
                    : ""
                }}</span
              >
            </div>
            <div class="w-1/12">
              <div>{{ transactionLists.gsmNetwork }}</div>
            </div>

            <div class="w-2/12">
              <span
                v-if="transactionLists.type === 'BUY'"
                @click.stop="copyReceptAdre"
              >
                {{
                  transactionLists.receptionAddress
                    ? transactionLists.receptionAddress.slice(0, 20)
                    : ""
                }}</span
              >
              <span v-else @click.stop="copyPhoneNumber">
                {{ transactionLists.phoneNumber }}</span
              >
            </div>

            <div class="w-28 mr-2">
              <boutton
                @click.stop="$emit('validatedTransaction')"
                label="En attente"
                v-if="transactionLists.state === 'WAITING'"
                background="#FFD354"
                radius="14px"
                height="26px"
                color="#343A44"
                size="12px"
                class="w-full"
              />
              <boutton
                label="Validé"
                v-if="transactionLists.state === 'PAID'"
                background="#E7F3EE"
                radius="14px"
                height="26px"
                color="#108455"
                size="12px"
                class="w-full"
              />
              <boutton
                label="Echoué"
                v-if="transactionLists.state === 'FAILED'"
                background="#E6ECF2"
                radius="14px"
                height="26px"
                color="#EC0001"
                size="12px"
                class="w-full"
              />
            </div>
            <div class="w-1/12 lg:block hidden">
              <div class="mb-1">
                {{ convertTimestamp(transactionLists.createdAt) }}
              </div>
            </div>
            <!-- <div @click.stop="$emit('deleteOrder')">supprimer</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boutton from "@/components/helper/add/button";
import nameLabell from "@/components/helper/add/nameLabell";

export default {
  name: "index-etat",
  components: { boutton, nameLabell },
  props: {
    transactionLists: {
      type: Object,
      default: function () {
        return {};
      },
    },
    clef: {
      type: Number,
    },
  },
  data() {
    return {};
  },

  methods: {
    // reverseArrayIndices(arr) {
    //   const reversedArray = [];
    //   const length = arr.length;

    //   for (let i = 0; i < length; i++) {
    //     reversedArray[length - 1 - i] = arr[i];
    //   }

    //   return reversedArray;
    // },
    copyMomoId() {
      const textToCopy = this.transactionLists.momoId;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("id momo copié");
    },
    copyHash() {
      const textToCopy = this.transactionLists.transactionHash;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Hash de transaction copié");
    },
    copyReceptAdre() {
      const textToCopy = this.transactionLists.receptionAddress;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Adresse de reception copiée");
    },
    copyPhoneNumber() {
      const textToCopy = this.transactionLists.phoneNumber;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Numéro momo copié");
    },
    copyTextToClipboard(text) {
      const input = document.createElement("textarea");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    convertTimestamp(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.description {
  color: #e36868;
}
.dashboard {
  font-family: $font-family;
}
</style>
