<template>
  <div class="w-full" :style="{ color: color }">
    <div class="text-c14 text-center w-full">{{ error }}</div>

    <div class="flex justify-center">
      <boutton
        label="Réessayer"
        size="13px"
        height="35px"
        radius="30px"
        background="#ccc"
        class="mt-5 w-1/6"
        @oga="$emit('info', true)"
      />
    </div>
  </div>
</template>

<script>
import boutton from "@/components/helper/add/button";

export default {
  name: "CedricDivider",
  components: {
    boutton,
  },

  props: {
    color: {
      type: String,
      default: "#909090",
    },
    error: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
</style>
