<template>
  <div class="empty-base">
    <div class="begin">
      <div class="content">
        <div class="flex items-center justify-center">
          <div class="text-center h-full">
            <div class="flex justify-center empty-icon">
              <img :src="icons.ic_empty" original width="113" height="113" />
            </div>
            <div class="flex justify-center empty-name text-gray-500 text-lg">
              {{ emptyTitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ic_empty from "@/assets/icons/ic_empty-state.svg";
export default {
  name: "empty-base",
  props: {
    emptyTitle: String,
  },
  data() {
    return {
      icons: { ic_empty },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.ip {
  color: #949ba7;
}
</style>
