<template>
  <div class="lg:flex mb-4">
    <div
      class="w-full lg:w-4/6 bg-white py-6 mr-6 rounded-lg border-t-4 mb-4 border-green-700"
    >
      <div class="lg:flex items-center justify-between px-6">
        <div class="flex items-center">
          <div class="font-montserrat font-normal text-lg text-gray-600 mr-2">
            Type de Transaction:
          </div>
          <div class="font-montserrat font-medium text-lg text-gray-400">
            <span v-if="driverData.type === 'BUY'">ACHAT</span>
            <span v-else-if="driverData.type === 'BUY UNITE'"
              >Achat d'unité</span
            >
            <span v-else>VENTE</span>
          </div>
        </div>
        <div class="font-semibold text-xl">{{ driverData.country }}</div>
        <div class="flex w-2/5 lg:w-2/12">
          <boutton
            @click="$emit('validatedTransaction')"
            label="En attente"
            v-if="driverData.state === 'WAITING'"
            background="#FFD354"
            radius="4px"
            height="26px"
            color="#343A44"
            size="16px"
            padding="14px"
            class="w-full"
          />
          <boutton
            label="Payé"
            v-if="driverData.state === 'PAID'"
            background="#E7F3EE"
            radius="4px"
            height="26px"
            padding="14px"
            color="#108455"
            size="16px"
            class="w-full"
          />
          <boutton
            label="Echoué"
            v-if="driverData.state === 'FAILED'"
            background="#E6ECF2"
            radius="4px"
            height="26px"
            padding="14px"
            color="#EC0001"
            size="16px"
            class="w-full"
          />
        </div>
      </div>
      <div class="border-b-2 border-gray-200 my-6"></div>
      <div class="lg:flex justify-between px-2 lg:px-6 mb-9">
        <div class="w-4/6">
          <div class="flex items-center mb-6">
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              Client :
            </div>
            <div class="flex items-center">
              <div class="font-montserrat font-medium text-lg text-black">
                {{ driverData.firstName + " " + driverData.lastName }}
              </div>
            </div>
          </div>
          <div class="flex items-center mb-6">
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              Date de commande :
            </div>
            <div class="font-montserrat font-medium text-lg text-black">
              {{ convertTimestamp(driverData.createdAt) }}
            </div>
          </div>
          <div class="flex items-center mb-6">
            <div
              class="lg:w-1/3 font-montserrat font-normal mr-2 text-lg text-gray-400"
            >
              <span v-if="driverData.type === 'BUY'">ID mobil money :</span>
              <span v-else>Hash de transaction :</span>
            </div>
            <div class="font-montserrat font-medium text-lg text-black">
              <span v-if="driverData.type === 'BUY'">{{
                driverData.momoId
              }}</span>
              <span v-else @click="copyHash"
                >{{ splitStringIntoParts(driverData.transactionHash, 20, 22) }}
                <span
                  class="text-base text-green-500 ml-4"
                  v-if="driverData.type === 'BUY'"
                  >Flash</span
                ></span
              >
            </div>
          </div>
          <div class="flex items-center mb-6">
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              <span v-if="driverData.type === 'BUY'">
                Address de reception :</span
              >
              <span v-else>Numéro de reception :</span>
            </div>
            <div class="font-montserrat font-medium text-lg text-black">
              <span v-if="driverData.type === 'BUY'" @click="copyAddr">
                {{
                  splitStringIntoParts(driverData.receptionAddress, 20, 22)
                }}</span
              >
              <span v-else>{{ driverData.phoneNumber }}</span>
            </div>
          </div>
          <div class="flex items-center mb-6">
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              Validée le :
            </div>
            <div class="font-montserrat font-medium text-lg text-black">
              <span
                v-if="
                  driverData.state === 'PAID' || driverData.state === 'FAILED'
                "
                >{{ convertTimestamp(driverData.updatedAt) }}</span
              >
              <span class="text-gray-400" v-if="driverData.state === 'WAITING'"
                >en cours</span
              >
              <span
                class="text-red-600 ml-4"
                v-if="driverData.state === 'FAILED'"
                >Echoué</span
              >
            </div>
          </div>
          <div
            class="flex items-center mb-6"
            v-if="driverData.state !== 'WAITING'"
          >
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              Validée par :
            </div>
            <div class="flex items-center">
              <div>
                <img
                  :src="'https://winaero.com/blog/wp-content/uploads/2018/08/Windows-10-user-icon-big.png'"
                  class="h-7 w-7 mr-2 rounded-full"
                />
              </div>
              <div class="font-montserrat font-medium text-lg text-black">
                {{ driverData.updatedBy }}
              </div>
            </div>
          </div>
          <div class="flex mb-6" v-if="driverData.state === 'FAILED'">
            <div
              class="lg:w-1/3 font-montserrat font-normal text-lg text-gray-400"
            >
              Note :
            </div>
            <div class="font-montserrat font-medium text-base text-gray-500">
              {{ driverData.rejectedNote }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="font-montserrat font-normal text-lg text-gray-500 mr-4">
            Montant :
          </div>
          <div class="font-montserrat font-bold text-2xl text-blue-700">
            {{ driverData.amountXof }} Fcfa
          </div>
        </div>
      </div>
      <div
        class="flex justify-center items-center text-gray-500 text-xl px-4 mx-4"
        v-if="driverData.state !== 'FAILED'"
      >
        <div v-if="driverData.type === 'BUY'">
          <span class="mr-2">Hash:</span>
          <span class="text-identityColor" @click="copyHash">{{
            splitStringIntoParts(driverData.transactionHash, 30, 30)
          }}</span>
        </div>
        <div v-else>
          <span class="mr-2">ID momo:</span>
          <span class="text-identityColor">{{ driverData.momoId }}</span>
        </div>
        <div><span class="text-base text-green-500 ml-4">Flash</span></div>
      </div>
    </div>

    <div class="w-full lg:w-2/6">
      <more-info :driver-data="driverData" />
    </div>
  </div>
</template>

<script>
import flecheLeft from "@/assets/icons/ic_fleche-left.svg";
import company from "@/assets/icons/ic_company.svg";
import moreInfo from "./more-info.vue";
import boutton from "@/components/helper/add/button";

export default {
  name: "new-details",
  components: { boutton, moreInfo },
  props: {
    driverData: Object,
  },
  data() {
    return {
      icons: { flecheLeft, company },
    };
  },
  created() {
    // console.log('seei-bill', this.driverData)
  },
  methods: {
    splitStringIntoParts(inputString, firstPartLength, lastPartLength) {
      const firstPart = inputString.substr(0, firstPartLength);
      const lastPart = inputString.substr(-lastPartLength);
      const dots = "......";
      return firstPart + dots + lastPart;
    },
    copyHash() {
      const textToCopy = this.driverData.transactionHash;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Hash de transaction copié");
    },
    copyAddr() {
      const textToCopy = this.driverData.receptionAddress;
      this.copyTextToClipboard(textToCopy);
      this.$toast.success("Addresse de reception copiée");
    },

    copyTextToClipboard(text) {
      const input = document.createElement("textarea");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
    },
    convertTimestamp(timestamp) {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  color: #2c5aa0;
}
</style>
