<template>
  <div class="index fixed w-full bg-white">
    <div class="mx-5 my-4">
      <div class="card">
        <div class="flex justify-between" v-if="user.loggedIn">
          <div class="flex items-center">
            <div class="flex items-center w-36 cursor-pointer">
              <img class="w-12" :src="images.logo" alt="logo" original />
              <span class="ml-2 font-extrabold text-3xl text-identityColor"
                >Flash</span
              >
            </div>
          </div>
          <div>
            <div
              class="flex items-center cursor-pointer"
              @click="islogg = !islogg"
            >
              <div>
                <img
                  class="w-12 mr-2"
                  :src="images.profile"
                  alt="profile"
                  original
                />
              </div>
              <div class="">
                <div v-if="user.data.email === 'samadousalahou@gmail.com'">
                  <div class="font-semibold text-base text-gray-400">
                    Samadou
                  </div>
                  <div class="font-normal text-base text-identityColor">
                    Chef technical officer
                  </div>
                </div>
                <div v-if="user.data.email === 'alphonsemehounme9@gmail.com'">
                  <div class="font-semibold text-base text-gray-400">
                    Alphonse
                  </div>
                  <div class="font-normal text-base text-identityColor">
                    Product manager
                  </div>
                </div>
                <div v-if="user.data.email === 'kassaloic@gmail.com'">
                  <div class="font-semibold text-base text-gray-400">Loïc</div>
                  <div class="font-normal text-base text-identityColor">
                    Business developer
                  </div>
                </div>
              </div>
              <img class="w-5 ml-2" :src="images.down" alt="down" original />
            </div>

            <div
              v-if="islogg"
              @click.prevent="signOut"
              class="flex absolute w-60 justify-between mx-3 cursor-pointer"
            >
              <div class=""></div>
              <div
                class="flex rounded-md bg-red-400 mr-2 px-3 py-2 hover:text-black text-white"
              >
                Déconnexion
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center h-screen items-center">
          <span>Vous n'êtes pas connecté</span>
          <a
            class="text-white ml-4 p-3 rounded-md bg-identityColor hover:bg-yellow-600 hover:text-gray-200"
            href="/lBLogin"
            >Connexion</a
          >
        </div>
      </div>
    </div>
  </div>

  <div v-if="user.loggedIn">
    <div
      class="px-5 lg:py-5 bg-gray-100 min-h-screen h-full pt-32 lg:pt-28"
      v-if="!activDetail"
    >
      <div class="lg:flex">
        <div class="w-full lg:w-2/4 h-full">
          <typeStats :donnes="typeStats" />
        </div>
        <div class="w-full lg:w-2/4 h-full">
          <stats :donnes="stats" />
        </div>
      </div>
      <div class="w-full">
        <div class="flex justify-between items-center mb-5">
          <div class="flex font-bold text-2xl font-serif">
            <div class="mr-2">Transactions</div>
            <!-- <div class="text-identityColor" v-if="orders.length !== 0">
              {{ orders.length - 1 }}
            </div> -->
          </div>

          <div
            class="bg-white w-3/5 lg:w-2/12 py-4 px-3 flex items-center rounded-md pl-4"
          >
            <img src="@/assets/icons/search.svg" alt="close" original />
            <input
              type="text"
              v-model="searchValue"
              placeholder="par type pays reseau"
              class="placeholder-gray-600 font-light px-4 border-l ml-4"
            />
          </div>
        </div>
        <div class="w-full pt-2 px-8 bg-white rounded-md shadow-md mt-0.5 mb-2">
          <steepSelect
            :value="position"
            :option="allSteep"
            @info="backSteep"
            family="Montserrat"
            size="16px"
            weight="600"
            color="#2C5AA0"
          />
        </div>
        <div class="w-full px-4 pt-6 bg-white rounded-t-md">
          <div
            class="flex items-center font-bold text-base mb-5 uppercase mt-4 px-2 op"
          >
            <div class="w-16">Num</div>
            <div class="w-24">Cat</div>
            <div class="w-2/12">user</div>
            <div class="w-1/12">mont. sats</div>
            <div class="w-1/12">mont. fiat</div>
            <div class="w-2/12 lg:block hidden">id momo/hash</div>
            <div class="w-1/12">reseau</div>
            <div class="w-2/12">adresse/numero</div>
            <div class="w-28">statut</div>
            <div class="w-1/12 lg:block hidden">date</div>
          </div>
          <div class="border-b w-full" />
        </div>
      </div>
      <div class="w-full px-4 pb-6 bg-white rounded-b-md">
        <liste
          v-for="(item, index) in filteredTransactions"
          :key="index"
          :clef="getClef(index, position)"
          :transactionLists="item"
          @click="selected(item)"
          @openTransactionDetails="backTransaction(item)"
          @validatedTransaction="validatedTransaction(item)"
        />
        <div class="flex justify-center">
          <easy-spinner v-if="charge" type="dots" size="50" class="mt-10" />
        </div>
        <div v-if="errorConnection" class="mt-10">
          <error-display :error="error" />
        </div>
        <div>
          <emptyBase
            :empty-title="emptyTitle"
            v-if="
              this.position === this.allSteep[1] &&
              typeStats.waitingList.length === 0 &&
              !charge
            "
          />
        </div>
      </div>
    </div>
    <div class="bg-gray-100 h-screen px-5 py-10 defile" v-if="activDetail">
      <transaction-details
        :one-transaction-details="transactionDetails"
        @validatedTransaction="validatedTransaction(transactionDetails)"
        @oga="backDetail"
      />
    </div>
    <validated-modal
      :transactionSelect="oneOrder"
      :adminConnected="adminConnected"
      v-if="editOrderForm"
      @close="close"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, ref, onUnmounted } from "vue";
import { getAuth } from "firebase/auth";
import searchIcon from "@/assets/icons/search.svg";
import liste from "./liste.vue";
import transactionDetails from "./transaction-details.vue";
import errorDisplay from "@/components/helper/add/errorDisplay";
import ValidatedModal from "./validated-modal.vue";
import emptyBase from "@/components/helper/add/empty-base";
import steepSelect from "@/components/helper/form/steepSelect";
import logo from "@/assets/images/img_logo.png";
import profile from "@/assets/icons/admin-profile.png";
import down from "@/assets/icons/down.svg";
import stats from "./stats.vue";
import typeStats from "./typeStats.vue";
import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "@firebase/firestore";

export default {
  name: "index-dashboard",
  components: {
    stats,
    typeStats,
    liste,
    errorDisplay,
    transactionDetails,
    ValidatedModal,
    emptyBase,
    steepSelect,
  },
  props: {
    search: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      islogg: false,
      icons: { searchIcon },
      images: { profile, logo, down },
      error: false,
      errorConnection: false,
      errorEm: false,
      emptyTitle: "Aucune transaction en attente pour le moment",
      charge: true,
      temoinSearch: null,
      searchValue: null,
      activDetail: false,
      transactionDetails: null,
      editOrderForm: false,
      IsDashB: false,
      orders: ref([]),
      time: [],
      oneOrder: {},
      adminConnected: null,
      allSteep: ["Toutes", "En attentes", "Validées", "Echouées"],
      position: "Toutes",
      stats: {
        sommeSats: null,
        sommeXof: null,
      },
      typeStats: {
        ordersList: [],
        waitingList: [],
        validatedList: [],
        failedList: [],
      },
    };
  },

  computed: {
    filteredTransactions: function () {
      if (this.position === this.allSteep[0]) {
        return this.sender;
      } else if (this.position === this.allSteep[1]) {
        return this.typeStats.waitingList;
      } else if (this.position === this.allSteep[2]) {
        return this.typeStats.validatedList;
      } else if (this.position === this.allSteep[3]) {
        return this.typeStats.failedList;
      } else {
        return [];
      }
    },
    sender: function () {
      let retour = this.orders;
      const search = this.searchValue;
      if (search !== this.temoinSearch) {
        retour = retour.filter((item) => {
          const normalizedSearch = search
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedFirstName = item.firstName
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedLastName = item.lastName
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedMomoId = item.momoId
            .toString()
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedPhoneNumber = item.phoneNumber
            ? item.phoneNumber
                .toString()
                .replace(/ /g, "")
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            : "";
          const normalizedCountry = item.country
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedType = item.type
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");
          const normalizedGsmNetwork = item.gsmNetwork
            .replace(/ /g, "")
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "");

          if (
            normalizedFirstName.indexOf(normalizedSearch) !== -1 ||
            normalizedLastName.indexOf(normalizedSearch) !== -1 ||
            normalizedMomoId.indexOf(normalizedSearch) !== -1 ||
            normalizedPhoneNumber.indexOf(normalizedSearch) !== -1 ||
            normalizedCountry.indexOf(normalizedSearch) !== -1 ||
            normalizedType.indexOf(normalizedSearch) !== -1 ||
            normalizedGsmNetwork.indexOf(normalizedSearch) !== -1
          ) {
            return true;
          }
        });
      }
      return retour;
    },
  },

  created() {
    this.getEmail();
  },

  methods: {
    getClef(index, position) {
      if (position === this.allSteep[0]) {
        return this.reversedKey(index) - 1;
      } else if (position === this.allSteep[1]) {
        return index;
      } else if (position === this.allSteep[2]) {
        return this.reversedKey(index) - 2;
      } else if (position === this.allSteep[3]) {
        return index + 1;
      } else {
        return index;
      }
    },
    reversedKey(index) {
      return this.sender.length - index;
    },
    openLogout() {
      this.islogg = true;
    },
    deleteOrder(item) {
      this.oneOrder = item;
      const db = getFirestore();
      const id = this.oneOrder.id;
      deleteDoc(doc(db, "orders", id));
    },
    validatedTransaction(item) {
      this.$emit("info", item);
      this.oneOrder = item;
      this.editOrderForm = true;
      this.$toast.info("Vous êtes sur le point de valider une transaction");
      //console.log("this item selected", this.oneOrder);
    },
    close() {
      this.editOrderForm = false;
      this.$emit("close", true);
    },
    selected(item) {
      this.$emit("info", item);
    },
    backTeam(t) {
      this.selectedItem = t;
    },

    goto(a) {
      this.$router.push({ path: a, query: { state: this.type } });
    },

    backSteep(a) {
      this.position = a;
    },

    backTransaction(b) {
      this.transactionDetails = b;
      this.activDetail = true;
    },
    backDetail(b) {
      this.activDetail = b;
    },
    getEmail() {
      if (this.user) {
        this.adminConnected = this.user.data ? this.user.data.email : "";
        return this.user;
      } else {
        return "Utilisateur non connecté";
      }
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const auth = getAuth();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      let userEmail = store.getters.user;
      return userEmail;
    });

    const signOut = async () => {
      await store.dispatch("logOut");
      router.push("/lBLogin");
    };
    return { user, signOut };
  },

  mounted() {
    const db = getFirestore();
    const latestQuery = query(
      collection(db, "orders"),
      orderBy("createdAt", "desc")
    );

    if (latestQuery) {
      const liveOrders = onSnapshot(latestQuery, (snapshot) => {
        if (snapshot.empty) {
          this.error = true;
          this.charge = false;
        } else {
          this.orders = snapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data,
              updatedAt: data.updatedAt || null,
              rejectedNote: data.rejectedNote || null,
            };
          });
          if (this.orders.length === 0) {
            this.errorEm = true;
          }
          this.charge = false;

          let sommeSats = 0;
          let sommeXof = 0;
          for (let i in this.orders) {
            if (this.orders[i].state === "PAID") {
              sommeSats += this.orders[i].amountSats;
              sommeXof += this.orders[i].amountXof;
              this.stats.sommeSats = sommeSats;
              this.stats.sommeXof = sommeXof;
            }
          }
          for (let i in this.orders) {
            if (this.orders[i].lastName !== "Tampon") {
              this.typeStats.ordersList.push(this.orders[i]);
            }
          }
          for (let i in this.orders) {
            if (this.orders[i].state === "WAITING") {
              this.typeStats.waitingList.push(this.orders[i]);
            }
          }
          for (let i in this.orders) {
            if (
              this.orders[i].state === "PAID" &&
              this.orders[i].lastName !== "Tampon"
            ) {
              this.typeStats.validatedList.push(this.orders[i]);
            }
          }
          for (let i in this.orders) {
            if (this.orders[i].state === "FAILED") {
              this.typeStats.failedList.push(this.orders[i]);
            }
          }
          // console.log("", this.typeStats.validatedList);
        }
      });

      onUnmounted(liveOrders);
    } else {
      this.charge = false;
      this.errorConnection = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.op {
  color: #909090;
}
.ip {
  color: #7f8896;
}
.defile {
  padding-top: 6%;
}
</style>
