<template>
  <div class="flex space-x-4">
    <div class="w-1/2" v-for="(item, i) in donnes" :key="i">
      <div class="bg-white shadow-lg rounded-lg p-2 lg:p-5 mb-3">
        <div class="flex justify-between items-center">
          <div>
            <div class="font-semibold text-gray-700 text-base mb-4">
              <div v-if="i === 'sommeSats'">Volume SAT</div>
              <div v-if="i === 'sommeXof'">Volume XOF</div>
            </div>
            <div class="font-extrabold text-xl lg:text-3xl text-identityColor">
              {{ item ? item.toLocaleString() : "" }}
            </div>
          </div>
          <div>
            <div v-if="i === 'sommeSats'">
              <img :src="icons.ic_sat" height="60" width="60" original />
            </div>
            <div v-if="i === 'sommeXof'">
              <img :src="icons.ic_fiat" height="60" width="60" original />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ic_sat from "@/assets/icons/ic_sat.svg";
import ic_fiat from "@/assets/icons/ic_fiat.svg";

export default {
  name: "stat",
  props: {
    donnes: {
      type: Object,
      default: null,
    },
  },
  components: {},

  data() {
    return {
      icons: {
        ic_sat,
        ic_fiat,
      },
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";

.ip {
  color: #606060;
}
</style>
